@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    font-family: 'Open Sans' !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.hover-anim {
  cursor: pointer;
  width:20%;
  height: 100%;
  transition: 400ms all!important;
}
.hover-anim strong{
  font-size:14px;
}
.table-container{
  min-height:50vh;
  width:100%;
  overflow: scroll;
}

.table-container table *{
  font-weight: 500!important;

  
}


thead{
  position:sticky;
  top:0;
  left:0;
  z-index: 1000;
  background-color: #fff;
}



.chart-container{
  width:70%;
  margin-left:15%;
}
@media only screen and (max-width: 900px) {
  .hover-anim {
    width:100%;
    height: 100%;
    flex-direction: row!important;
    justify-content: space-between!important;
  }
  .hover-anim span{
    display: block;
    width:30%!important;
    font-size: 30px!important;
  }
  .hover-anim strong{
    display: block;
    font-size: 10px;
    width: 30% !important;
  }
  
}
.hover-anim:hover {
  transform: scale(1.06958071);
}
.hover-anim.active{
  background-color:rgb(174, 213, 129);
  transform: scale(.968458071);
  color:#fff;
}


@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
  *{
    font-size: 15px;
    font-family: sans-serif;
  }
}



.input-list{
  margin-top: 20;margin-bottom: 20;display: flex;
  flex-wrap: wrap;
  margin-bottom:30px;
  border:none;
  outline: none;
}
.input-list input{
  width:20%;
}

.popup{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index:98997 !important;
  background-color: rgba(0,0,0,0.9);
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 60vw;
  height: 80vh;
  top:10vh;
  left:20vw;
  z-index: 9900089999!important;
  border-radius: 40px;
}
.popup-container .each-slide-effect{
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container .each-slide-effect img {
  height: 80vh;
}
.close{
  position: fixed;
  top:0;right:0;
  z-index: 1001;
  cursor: pointer;
  background-color: red;
  font-size:42px;
  color:#fff;
  padding:10px 25px;
}

.mobile-show {
  display: none;
}
@media only screen and (max-width: 900px) {
  .input-list input{
    width:100%;
    margin-bottom:25px;
  }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 95vw;
      height: 80vh;
      top: 20vh;
      left: 2.5vw;
      z-index: 1999000!important;
      border-radius: 40px;
    }
  
    .popup-container .each-slide-effect {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .popup-container .each-slide-effect img {
      width: 95vw;
      height: unset;
    }
    .mobile-hide{
      display:none;
    }
    .mobile-show {
      display: block;
    }
}


.admin-btn{
  position: fixed;
  bottom:30px;left:30px;
  font-size:30px;
  background-color: #ddd;
  padding:10px;
  border-radius: 100%;
  cursor: pointer;

}
.admin-btn:hover{
  background-color: #eee;
}
.admin-tables{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top:0;
  overflow: scroll !important;
  background-color: #fff;
}


.admin-tables td{
  width:30%;
}

.notification-container{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0,0,0,0.7);
  display:flex;justify-content:center;align-items:center;
}
.notification-container .notification{
  width:50%;
  min-height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction:column;
  border-radius: 40px;
  padding: 40px;
}
.notification img{
  margin-bottom:30px;
}
.notification h1{
  font-size:22px;
  width:80%;
}
.notification button{
  margin-top:60px;
  padding:10px 30%;
  border:none;
  outline: none;
  background-color: rgb(95, 127, 60);
  color:#fff;
  border-radius: 40px;
  font-size:24px;
  cursor: pointer;
  transition:400ms all;
}
.notification button:hover{
  background-color: rgb(174, 213, 129);
}
.notification h1 strong{
  color:red;
  font-size:24px;
}
@media only screen and (max-width: 900px) {
  .notification-container .notification{
    width: 90%;
    min-height: 60%;
  }
}

.company{
  width:100%;
  margin:0;
  padding:0;
  background:#000;
  color:#fff;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:24px;
}

.company img{
  height:40px;
}

.table {
  width: 100%;
  /* Tablonun genişliğini ayarlayın */
}

thead {
  position: sticky;
  /* Başlığı sticky hale getirir */
  top: 0;
  /* Başlığın konumunu sayfanın en üstüne ayarlar */
  background-color: white;
  /* Arkaplan rengini istediğiniz gibi ayarlayın */
  z-index: 10;
  /* Diğer öğelerin üzerine çıkmasını sağlar */
}

/* İsteğe bağlı: Sütun genişliklerini korumak için */
th {
  width: 100px;
  /* İstediğiniz genişliği ayarlayın */
}

